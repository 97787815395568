import C from "./WSearchField.module.css";

import type { SearchFieldProps } from "react-aria-components";
import { Button, Input, SearchField } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";

interface WSearchFieldProps extends SearchFieldProps {}

export function WSearchField(props: WSearchFieldProps) {
    return (
        <SearchField className={C.SearchField} aria-label="Search query" {...props}>
            {({ isEmpty }) => (
                <>
                    {isEmpty && <MaskIcon className={cn(C.Icon, "jp-icon-search size-0.875rem text-muted")} />}
                    <Input className={C.Input} />
                    <Button className={C.Button}>✕</Button>
                </>
            )}
        </SearchField>
    );
}
