export function mapFrom<T, TKey>(iterable: Iterable<T>, keyFn: (item: T) => TKey): Map<TKey, T>;
export function mapFrom<T, TKey, TValue>(
    iterable: Iterable<T>,
    keyFn: (item: T) => TKey,
    valueFn: (item: T) => TValue,
): Map<TKey, TValue>;

/**
 * Creates a new {@link Map} object from an iterable, using the specified key and value functions.
 *
 * @template T The type of items in the iterable.
 * @param iterable - The iterable to create the map from.
 * @param keyFn - The function to extract the key from each item.
 * @param valueFn - Optional function to transform each item before adding it to the map.
 */
export function mapFrom<T, TKey, TValue = T>(
    iterable: Iterable<T>,
    keyFn: (item: T) => TKey,
    valueFn?: (item: T) => TValue,
) {
    const map = new Map<TKey, TValue>();
    for (const item of iterable) {
        map.set(keyFn(item), valueFn ? valueFn(item) : (item as unknown as TValue));
    }
    return map;
}

/**
 * Creates a new {@link Map} object from an iterable by applying a function to each item.
 *
 * @param iterable - The iterable to create the map from.
 * @param fn - The mapping function that transforms each item into a key-value pair.
 */
export function mapFromEntries<T, TKey, TValue>(iterable: Iterable<T>, fn: (item: T) => [TKey, TValue]) {
    const map = new Map<TKey, TValue>();
    for (const item of iterable) {
        const [key, value] = fn(item);
        map.set(key, value);
    }
    return map;
}

/**
 * `map()`s over the entries of a {@link Map} to an array of transformed values.
 *
 * @param map - The Map to be mapped.
 * @param fn - The transformation function to be applied to each entry.
 *             It receives the key and the value of the entry as arguments.
 */
export function mapEntries<T, TKey, TRes>(map: Map<TKey, T>, fn: (key: TKey, item: T) => TRes): TRes[] {
    const result = new Array<TRes>(map.size);
    let i = 0;
    for (const [key, item] of map) {
        result[i++] = fn(key, item);
    }
    return result;
}

/** `map()`s over the values of a {@link Map} to an map of transformed values. */
export function mapOnValues<T, TKey, TRes>(map: Iterable<[TKey, T]>, fn: (item: T) => TRes): Map<TKey, TRes> {
    const result = new Map<TKey, TRes>();
    for (const [key, item] of map) {
        result.set(key, fn(item));
    }
    return result;
}

type Maps = readonly (Iterable<[unknown, unknown]> | undefined)[];
type KeyType<T extends Maps> = T extends readonly Iterable<[infer K, unknown]>[] ? K : never;
type ValueType<T extends Maps> = T extends readonly Iterable<[unknown, infer V]>[] ? V : never;

/** Merges multiple maps into a single map. For duplicate keys, the last map wins. */
export function mergeMaps<
    TMaps extends readonly (Iterable<[TKey, TValue]> | undefined)[],
    TKey = KeyType<TMaps>,
    TValue = ValueType<TMaps>,
>(maps: TMaps) {
    const result = new Map<TKey, TValue>();
    for (const map of maps) {
        if (!map) continue;
        for (const [key, value] of map) {
            result.set(key, value);
        }
    }
    return result;
}

export function mapGetOrCreate<K, V, VC extends V = V>(map: Map<K, V>, key: K, create: () => VC): V {
    let value = map.get(key);
    if (!value) {
        value = create();
        map.set(key, value);
    }
    return value;
}
