import C from "./ProgressBar.module.css";

import type { ReactNode } from "react";
import { Label, ProgressBar } from "react-aria-components";
import { cn } from "../utils/classNames.ts";

export interface WProgressTickerProps {
    label: WProgressBarProps["label"];
    startTime: DOMHighResTimeStamp;
    lifeTime: DOMHighResTimeStamp;
}

export interface WProgressBarProps {
    label: ReactNode;
    percentage: number;
    inToast?: boolean;
}

import isChromatic from "chromatic";
import { useEffect, useState } from "react";

export function WProgressTicker({ startTime, lifeTime, label }: WProgressTickerProps) {
    const [currentPercentage, setCurrentPercentage] = useState(0);

    useEffect(() => {
        if (isChromatic()) {
            setCurrentPercentage(50);
            return;
        }

        if (startTime) {
            const updatesPerSecond = 5;
            const updateInterval = 1000 / updatesPerSecond;
            const interval = setInterval(() => {
                setCurrentPercentage((prev) => {
                    const now = performance.now();
                    const percentage = ((now - startTime) / lifeTime) * 100;
                    if (prev >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return percentage;
                });
            }, updateInterval);
            return () => clearInterval(interval);
        }
    }, [lifeTime, startTime]);
    return <WProgressBar inToast percentage={currentPercentage} label={label} />;
}

export function WProgressBar({ label, percentage, inToast }: WProgressBarProps) {
    return (
        <ProgressBar
            className={cn(C.Progress, inToast && C.inToast)}
            value={percentage}
            isIndeterminate={percentage == null}
        >
            {({ valueText }) => (
                <>
                    <Label className={C.Label}>{label}</Label>
                    <span className={C.value}>{valueText}</span>
                    <div className={C.bar}>
                        <div className={C.fill} style={{ width: `${percentage}%` }} />
                    </div>
                </>
            )}
        </ProgressBar>
    );
}
